// OverlayComponent.js
import React, { useEffect, useRef } from 'react';
import styles from '../style/ARShades_PD_Interface.module.css'; // Assicurati di aggiornare il percorso al file CSS
import { gsap } from 'gsap';

const ARPDMeter_CheckLicence = ({ isAllowed, isLicenseValid, handleUserStart, animateProps, isMobileView , logoArPDMeter  }) => {

  const containerRef = useRef(null); // Referenza al contenitore per l'animazione
  const logoRef = useRef(null);
  const logoRefEX = useRef(null)
  const validLicenseRef = useRef(null);
  const invalidLicenseRef = useRef(null)
  const licenseCheckRef = useRef(null);

  useEffect(() => {

     // Inizializza i loghi con opacità appropriata
     gsap.set(logoRef.current, { opacity: isLicenseValid ? 1 : 0 });
     gsap.set(logoRefEX.current, { opacity: isLicenseValid ? 0 : 1 });
     gsap.set(validLicenseRef.current, { opacity: isLicenseValid ? 1 : 0 });
     gsap.set(invalidLicenseRef.current, { opacity: isLicenseValid ? 0 : 1 });

    // Animazione GSAP che parte da scala 0 a scala 1
    gsap.set(containerRef.current, {
      opacity: 0,
      width: 0,
      height: 0,
    });

    if(isMobileView){
      gsap.to(containerRef.current, {
        opacity: 1,
        width: "370px",
        height: "480px",
        duration: 0.5,
        ease: 'power1.out',
      });
    }else{
 // Animazione che fa sembrare il contenitore di espandersi attorno al logo
 gsap.to(containerRef.current, {
  opacity: 1,
  width: "680px",
  height: "480px",
  duration: 0.5,
  ease: 'power1.out',
});
    }
   

    gsap.set(licenseCheckRef.current, { opacity: 1 });

  }, []); // L'array vuoto assicura che l'effetto venga eseguito solo al montaggio

  useEffect(() => {
  
    if(isLicenseValid){
 // Ascolta i cambiamenti di isLicenseValid

 gsap.to(logoRef.current, { opacity: isLicenseValid ? 1 : 0, duration: 1 });

 gsap.to(validLicenseRef.current, { opacity: isLicenseValid ? 1 : 0, duration: 1 });
    }else{
      gsap.to(logoRef.current, { opacity: isLicenseValid ? 1 : 0, duration: 1 });
      gsap.to(logoRefEX.current, { opacity: isLicenseValid ? 0 : 1, duration: 1});
     
      gsap.to(validLicenseRef.current, { opacity: isLicenseValid ? 1 : 0, duration: 1 });
      gsap.to(invalidLicenseRef.current, { opacity: isLicenseValid ? 0 : 1, duration: 1});
    }
   

  }, [isLicenseValid]);
  
  useEffect(() => {
    if (animateProps) {
      // Crea una nuova timeline
      console.log("ANIMATE LICENSE")
      // Aggiungi le animazioni alla timeline
      gsap.to(containerRef.current, { ...animateProps });
      gsap.to(licenseCheckRef.current, { ...animateProps }); // Il secondo parametro "0" sincronizza queste animazioni all'inizio
      gsap.to(logoRef.current, {top:'40px', duration:0.5})

      // Aggiungi un'altra animazione per ridurre l'opacità verso la fine della timeline
      // Usa un delay leggermente inferiore alla durata totale per far iniziare questa animazione prima che le precedenti siano completamente finite,
      // assicurando una transizione fluida.

       gsap.to(containerRef.current, { opacity: 0, duration: 1 , delay:0.5});
       gsap.to(containerRef.current, {zIndex:0, delay:0.7})
      //  gsap.to(containerRef.current, {delay:1,zIndex:0})

       }
  }, [animateProps]);
  
  
  return (
    // <div className={styles.overlay}>
    <div ref={licenseCheckRef}>
      <div className={styles.licenseContainer} onClick={handleUserStart} ref={containerRef}>


      <video autoPlay loop muted className={styles.videoBackground}>
        <source src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/ARPDMeter%2FImage%2FAR%20PD%20Meter%20Video%20Background-2.mp4?alt=media&token=78d4e2a0-8b27-48b3-b7f9-771594ee2b95" type="video/mp4" />
        Il tuo browser non supporta il tag video.
      </video>
     
      <div className={styles.overlayContent}>

  <img ref={logoRef} className={styles.logoArpdMeter} src={logoArPDMeter}></img>


  <img ref={logoRefEX} className={styles.logoArpdMeter} src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/ARPDMeter%2Ficon%2FARShades%20PD%20Meter%20Sorry%20Icon%20icon%203.png?alt=media&token=9e2d0071-b3d3-424b-93ab-799c20513e28"></img>


      <div ref={validLicenseRef} className={styles.containerTextLicense}>

        {/* VALID LICENSE */}
        <div className={styles.overlayText}>
          <p className={styles.highlight}>AR PD Meter</p>
          <p className={styles.subtitle}>your Pupillary Distance instantly</p>
        </div>

         <p
            className={styles.getStartedButton}
          > 
          
           {isAllowed && (
            <>
            NOT ALLOWED
            </>
          )}
              Click to start
          </p>
       
        </div>
          
          {/* INVALID LICENSE */}
          <div ref={invalidLicenseRef} className={styles.containerTextLicense} >

          <div className={styles.overlayText}>
          <p className={styles.highlight}>SORRY</p>
          <p className={styles.subtitle}>ARShades License has expired or is invalid</p>
        </div>

        <p className={styles.overlayTextERR}>
            If you are the Owner Contact Us
        </p>

        </div>
         
     
      </div>
    </div>
    </div>
  );
};

export default ARPDMeter_CheckLicence;
