import React from 'react';


class Policy{
    constructor(doc){
        this.id = doc.id;
        this.description_it = doc.privacy_policy_it;
        this.description_en = doc.privacy_policy
    }

    toString(){
        return `id: ${this.id}`;
    }
}

  //FIrestore Data Converter
  export const policyConverte ={
    toFirestore: (policy) => {
        return {
            description_it: policy.privacy_policy_it,
            description_en: policy.privacy_policy,
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Policy(data);
    }
  };

  // Funzione per convertire i dati della policy ricevuti dalla Cloud Function
 export function policyConverter(data) {
    // Assumiamo che 'data' sia l'oggetto con i dati della policy direttamente dalla tua Cloud Function
    return new Policy(data);
}