// ResultsDisplay.jsx
import React from 'react';
import styles from "../style/ARShades_PD_Interface.module.css";

const ARPDMeter_Footer = ({
  
  }) => (
     <div className={styles.footer}>
        Powered by <a href="" className={styles.footerLink}>ARShades</a>
     </div>
  );
  
  export default ARPDMeter_Footer;