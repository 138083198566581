import React from 'react';
import styles from '../style/ARShades_PD_Interface.module.css';

// Definizione del componente ARPDMeter_Feedback
const ARPDMeter_Feedback = ({
  feedbackContainerClassName,
  circleRefs,
  lineRefs,
  controlStates,
  feedbackMessage,
  circleClassName,
  feedbackContainerRef,
  circleContainerClassName,
}) => {
  // Assumi che `circleClassName` sia definito globalmente o passato come prop se necessario

  return (
    <div className={feedbackContainerClassName} ref={feedbackContainerRef}>
      <div className={circleContainerClassName}> {/* Sostituisci questo con la classe corretta */}
        {/* Controllo Distanza */}
        <div ref={circleRefs.distance} className={`${circleClassName} ${controlStates.distance === 'superato' ? styles.green : controlStates.distance === 'inCorso' ? styles.inCorso : styles.red}`}>
          <div className={styles.innerCircle}></div>
        </div>
        <div ref={lineRefs.distance} className={`${styles.line} ${controlStates.distance === 'superato' ? styles.green : ''}`}></div>

        {/* Controllo Luminosità */}
        <div ref={circleRefs.brightness} className={`${circleClassName} ${controlStates.brightness === 'superato' ? styles.green : controlStates.brightness === 'inCorso' ? styles.inCorso : styles.red}`}>
          <div className={styles.innerCircle}></div>
        </div>
        <div ref={lineRefs.brightness} className={`${styles.line} ${controlStates.brightness === 'superato' ? styles.green : controlStates.distance === 'superato' && controlStates.brightness !== 'inCorso' ? styles.green : ''}`}></div>

        {/* Controllo Occhiali */}
        <div ref={circleRefs.glasses} className={`${circleClassName} ${controlStates.glasses === 'superato' ? styles.green : controlStates.glasses === 'inCorso' ? styles.inCorso : styles.red}`}>
          <div className={styles.innerCircle}></div>
        </div>
        <div ref={lineRefs.glasses} className={`${styles.line} ${controlStates.glasses === 'superato' ? styles.green : controlStates.brightness === 'superato' && controlStates.glasses !== 'inCorso' ? styles.green : ''}`}></div>

        {/* Controllo Centraggio */}
        <div ref={circleRefs.centering} className={`${circleClassName} ${controlStates.centering === 'superato' ? styles.green : controlStates.centering === 'inCorso' ? styles.inCorso : styles.red}`}>
          <div className={styles.innerCircle}></div>
        </div>
        <div ref={lineRefs.centering} className={`${styles.line} ${controlStates.centering === 'superato' ? styles.green : controlStates.glasses === 'superato' && controlStates.centering !== 'inCorso' ? styles.green : ''}`}></div>

        {/* Controllo Allineamento */}
        <div ref={circleRefs.alignment} className={`${circleClassName} ${controlStates.alignment === 'superato' ? styles.green : controlStates.alignment === 'inCorso' ? styles.inCorso : styles.red}`}>
          <div className={styles.innerCircle}></div>
        </div>

      </div>
      <div id="feedbackGeneral" className={styles.feedbackLabel}>
        {feedbackMessage}
      </div>
    </div>
  );
};

export default ARPDMeter_Feedback;
