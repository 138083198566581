import React, { useState, useEffect, useRef } from "react";
import styles from "../style/ARShades_PD_Interface.module.css";
import * as vision from "@mediapipe/tasks-vision";
import { calculateFaceCenter, checkFaceCentering, setupCamera, calculateAverageBrightness, calculateIrisDistanceCamera, } from "../utils/PdUtils";
import axios from "axios";
import UAParser from "ua-parser-js";
import ARPDMeter_CheckLicence from "./ARPDMeter_CheckLicence";
import PermissionAndPolicy from "./ARPDMeter_PrivacyPolicy";
import { gsap } from 'gsap';
import ResultsDisplay from "./ARPDMeter_results";
import ARPDMeter_Footer from "./ARPDMeter_Footer";
import ARPDMeter_Feedback from "./ARPDMeter_Feedback";
import GlassesContainer from "./GlassesContainer";
import PropTypes from 'prop-types';
import thumbsUp from '../asset/icon/thumbs-down.svg';
import thumbsDown from '../asset/icon/thumbs-up.svg';
import ARPDMeter_BottomContainer from './ARPDMeter_BottomContainer';


const { FaceLandmarker, FilesetResolver, DrawingUtils, ImageClassifier } = vision;

const setCSSVariable = (variable, value) => {
  document.documentElement.style.setProperty(variable, value);
};

function ARShades_PD_Interface({ licenseToken, onResultAvailable = null, customClass = {}, }) {

  // const allowedDomains = ["trynext.it", "anotherdomain.com"]; // Aggiungi i domini consentiti
  // const allowIframe = true; // Permetti l'uso in iFrame
  // const allowedReferrers = ["referrer1.com", "referrer2.com"]; // Aggiungi i referrer consentiti

  const containerClassName = styles.container;
  const circleContainerClassName = styles.circleContainer;
  const circleClassName = styles.circle;
  const feedbackContainerClassName = styles.feedbackContainer;
  const feedbackContainerRicalcolateClassName =  styles.feedbackContainerRicalcolate;
  const feedbackResultsClassName =  styles.feedbackResults;
  const feedbackResultLargeClassName = styles.feedbackResultLarge;
  const feedbackResultsSmallClassName =  styles.feedbackResultsSmall;
  const feedbackResultSmallClassName = styles.feedbackResultSmall;


  const redClassName = customClass.red || styles.red;
  const startButtonClassName = customClass.startButton || styles.startButton;


  const primaryColor = customClass.primaryColor || "#42B1AC";
  const secondaryColor = customClass.secondaryColor || "#42B1AC";
  const backgroundColorProps = customClass.backgroundColorProps || "#FFFFFF";
  const fontColorPrimary = customClass.fontColorPrimary || "#FFFFFF";
  const fontColorSecondary = customClass.fontColorSecondary || "#000000";
  const fontColorResult = customClass.fontColorResult || fontColorPrimary;
  const feedbackDotsColor = customClass.feedbackDotsColor || primaryColor;
  const fontFamily = customClass.fontFamily || 'Lexend'
  const logoArPDMeter = customClass.logoARPDMeter || "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/ARPDMeter%2Ficon%2FARShades%20PD%20Meter%20icon%202.png?alt=media&token=fa39133f-8d16-4e3d-bbf0-1a9aea599182"


  const [isRecalculated, setIsRecalculated] = useState(false);
  const parser = new UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [faceMesh, setFaceMesh] = useState(null);
  const [imageClassifier, setImageClassifier] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [arpd_meter_consumptionRef, setARPDMeter_consumption] = useState(null);
  const [isLicenseValid, setIsLicenseValid] = useState(true);
  const [calculate, setCalcolate] = useState(true);
  const [collectedData, setCollectedData] = useState([]);
  const measurementStartedRef = useRef(false);
  const [serverResponse, setServerResponse] = useState([]);
  const [isResult, setResult] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [hasGlassesOn, setHasGlassesOn] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight, });
  const [feedback, setFeedback] = React.useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [animateProps, setAnimateProps] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [controlStates, setControlStates] = useState({
    distance: 'nonIniziato',
    brightness: 'nonIniziato',
    glasses: 'nonIniziato',
    centering: 'nonIniziato',
    alignment: 'nonIniziato',
  });

  const [istructionDone, setIstructionDone] = useState(false);
  const instructionDoneRef = useRef(istructionDone);

  const controlOrder = ['distance', 'brightness', 'glasses', 'centering', 'alignment'];

  const approvedFrames = useRef([]);
  const isApproved = useRef(false);

  const circleRefs = {
    distance: useRef(null),
    brightness: useRef(null),
    glasses: useRef(null),
    centering: useRef(null),
    alignment: useRef(null)
  };

  const lineRefs = {
    distance: useRef(null),
    brightness: useRef(null),
    glasses: useRef(null),
    centering: useRef(null)
  };

  const feedbackContainerRef = useRef(null);
  const permissionPolicyRef = useRef(null);
  const pdContainerRef = useRef();
  const frameCountRef = useRef(0);
  const resultContainerRef = useRef(null);
  const arpdmeterIstructionRef = useRef(null);
  const stagingImage = useRef(null);
  const alertTooltip = useRef(null)

  let screenRatio = 480 / 640;
  let maxFrame = 1;
  let stay = 15;
  let width_mobile = 480;
  let height_mobile = 480;
  let width_desktop = 480;
  let height_desktop = 480;
  const [isAllowed, setIsAllowed] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);



  useEffect(() => {
    // Aggiorna la variabile CSS --primary-color
    setCSSVariable('--primary-color', primaryColor);
    setCSSVariable('--background-color', backgroundColorProps);
    setCSSVariable('--font-color', fontColorPrimary);
    setCSSVariable('--secondary-color', secondaryColor);
    setCSSVariable('--font-color-secondary', fontColorSecondary);
    setCSSVariable('--font-family', fontFamily)
  }, []);

  // useEffect(() => {
  //   const inIframe = window.self !== window.top;
  //   const currentDomain = window.location.hostname;
  //   const referrer = document.referrer ? new URL(document.referrer).hostname : '';

  //   const isDomainAllowed = (domain) => {
  //     return allowedDomains.length === 0 || allowedDomains.includes(domain);
  //   };

  //   const isReferrerAllowed = (ref) => {
  //     return allowedReferrers.length === 0 || allowedReferrers.includes(ref);
  //   };

  //   if ((inIframe && !allowIframe) ||
  //     (allowedDomains.length > 0 && !isDomainAllowed(currentDomain)) ||
  //     (allowedReferrers.length > 0 && !isReferrerAllowed(referrer))) {
  //     console.error("Access denied: domain, referrer, or iframe restrictions not met");
  //     return;
  //   }

  //   setIsAllowed(true);

  // }, [allowedDomains, allowIframe, allowedReferrers]);

  function isTabletUserAgent() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /ipad|android(?!.*mobile)|tablet/.test(userAgent);
  }

  function getOrientation() {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches;
    return isPortrait ? 'portrait' : 'landscape';
  }

  function getDeviceType() {
    const width = window.innerWidth;
    const orientation = getOrientation();

    if (isTabletUserAgent()) {
      if (orientation === 'portrait') {
        return 'tablet';
      }
      return 'tablet';
    }

    if (width < 768) {
      return 'mobile';
    } else if (width < 1024) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [orientation, setOrientation] = useState(getOrientation());

  const isMobileView = deviceType === 'mobile';

  useEffect(() => {
    if (isReady && privacyPolicy) {
      analyzeVideo();
      // console.log("Enter")
    }
  }, [isReady, privacyPolicy]);

  useEffect(() => {
    async function initializeFaceLandmarker() {
      const wasmFolderPath =
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm";
      const filesetResolver = await FilesetResolver.forVisionTasks(
        wasmFolderPath
      );

      const faceLandmarker = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fface_landmarker%20(4).task?alt=media&token=3438388b-bc3e-4713-83e1-0c40327af656`,
            delegate: "GPU",
          },
          outputFaceBlendshapes: true,
          runningMode: "IMAGE",
          outputFacialTransformationMatrixes: true,
          numFaces: 1,
          selfieMode: true,
        }
      );
      setFaceMesh(faceLandmarker);
    }

    async function createImageClassifier() {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      );
      const imageClassifier = await ImageClassifier.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2FTest%2Fefficientnet_lite0%20(1).tflite?alt=media&token=1fca7164-e6f1-44d7-9455-e026a259cdaf`,
        },
        categoryAllowlist: ["sunglasses", "sunglass"],
        scoreThreshold: 0.02,
      });

      setImageClassifier(imageClassifier);
    }

    Promise.all([
      initializeFaceLandmarker(),
      createImageClassifier(),
    ])
      .then(() => {
        setIsReady(true);
      })
      .catch((error) => {
        // console.error("Initialization failed:", error);
      });

    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight, });
    };

    window.addEventListener("resize", handleResize);

    gsap.to(pdContainerRef.current, { opacity: 0 });
    gsap.set(permissionPolicyRef.current, { opacity: 0 });
    gsap.set(feedbackContainerRef.current, { opacity: 0 });
    gsap.set(resultContainerRef.current, { opacity: 0 });
    gsap.to(arpdmeterIstructionRef.current, { opacity: 0 });
    gsap.to(alertTooltip.current, { opacity:0});

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      frameCountRef.current >= maxFrame + stay
    ) {
      // console.log("Send");
      sendDataToBackend([collectedData, isMobileView]);
    }
  }, [collectedData, maxFrame]);

  useEffect(() => {
    instructionDoneRef.current = istructionDone;
  }, [istructionDone]);

  const handleUpVote = () => {
    setFeedback(true);
    provideSessionFeedback(true);
  };

  const handleDownVote = () => {
    setFeedback(false);
    provideSessionFeedback(false);
  };

  const handleAcceptPrivacyPolicy = async () => {
    // console.log("Is Ready");
    // console.log("ACCETTATA", isVideoReady);
    setPrivacyPolicy(true);
  
    if (isMobileView) {
      gsap.to(permissionPolicyRef.current, { width: "370px", height: "480px", duration: 1 });
    } else {
      gsap.to(permissionPolicyRef.current, { width: "640px", height: "480px", duration: 1 });
    }
  
    gsap.to(permissionPolicyRef.current, { opacity: 0, delay: 0.5 });
    gsap.to(permissionPolicyRef.current, { zIndex: 0 });
  
    gsap.to(pdContainerRef.current, { opacity: 1, duration: 1, delay: 0.5 });
    gsap.to(feedbackContainerRef.current, { opacity: 1 });
    gsap.to(feedbackContainerRef.current, { delay: 0.5, zIndex: 13 });
    gsap.to(arpdmeterIstructionRef.current, { opacity: 1, duration: 1, delay: 0.5 });
    gsap.to(arpdmeterIstructionRef.current, { zIndex: 14, delay: 0.5 });
  
    // Visualizza il tooltip dopo aver accettato la privacy policy
    setShowTooltip(true);
  
    // Nasconde il tooltip dopo 5 secondi
    setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
  };
  

  



  const [isProcessing, setIsProcessing] = useState(false);

  const handleUserStart = async () => {

    if (isProcessing) return;

    setIsProcessing(true);

    try {
      const response = await axios.post(
        // "https://arpdmeterlicense-xcule5rnvq-uc.a.run.app",
        "https://arpdmeterlicense-xcule5rnvq-uc.a.run.app",
        // "http://127.0.0.1:5001/arshades-7e18a/us-central1/ARPDMeterLicense",
        {
          licenseKey: licenseToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Response", response)

      if (!response.data.valid) {
        throw new Error("Licenza non valida");
      }
      setARPDMeter_consumption(response.data.arpd_meter_consumption);

      showPermissionPolicy();

      await setupCamera(videoRef, isMobileView)

      await videoRef.current.play();

      setIsVideoReady(true);

    } catch (error) {
      // console.error('Errore:', error);
      setIsLicenseValid(false);
    } finally {
      setIsProcessing(false);

    }
  };

  const showPermissionPolicy = () => {
    const targetWidth = permissionPolicyRef.current.offsetWidth;
    const targetHeight = permissionPolicyRef.current.offsetHeight;

    // console.log('Ripasso');
    gsap.fromTo(permissionPolicyRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5, delay: 0.5 });
    const animationProperties = {
      width: targetWidth,
      height: targetHeight,
      duration: 1,
    };

    setAnimateProps(animationProperties);
  };

  function handleGetStarted() {
    gsap.to(arpdmeterIstructionRef.current, { opacity: 0, duration: 1 });
    gsap.to(pdContainerRef.current, { delay: 1, zIndex: 15 });
    setIstructionDone(true);
  }

  function setRicalcolate() {
    frameCountRef.current = 0;
    measurementStartedRef.current = false;
    stagingImage.current = null;
    isApproved.current = false;
    approvedFrames.current = [];
    setCollectedData([]);
    setHasGlassesOn(false);
    setServerResponse([]);
    setCalcolate(true);
    setResult(false);
    setIsRecalculated(true);
    setFeedback(null);
    // console.log("Ricalculate");
    gsap.to(resultContainerRef.current, { opacity: 0, duration: 2 });

  }

  const filterLandmarks = (landmarks) => {
    return {
      473: landmarks[473],
      474: landmarks[474],
      468: landmarks[468],
      469: landmarks[469],
      234: landmarks[234],
      454: landmarks[454],
      10: landmarks[10],
      152: landmarks[152],
      168: landmarks[168],
      39: landmarks[39],
      42: landmarks[42],
      27: landmarks[27],
    };
  };

  const sendDataToBackend = async (data) => {
    try {
      const requestBody = {
        data,
        apiConsumptionId: arpd_meter_consumptionRef,
        arShadesSubscriptionId: licenseToken,
        browser: `${browser.name} - ${browser.version}`,
        os: `${os.name} - ${os.version}`,
        isRecalculated: isRecalculated,
        hasGlassesOn: hasGlassesOn,
      };

      const response = await axios.post(
        // "http://127.0.0.1:5001/arshades-7e18a/us-central1/processPdData" ,
        "https://processpddata-xcule5rnvq-uc.a.run.app",
        requestBody
      );

      setServerResponse(response.data.receivedData);
      setResult(true);
      const feedbackElement = document.getElementById("feedbackGeneral");
      feedbackElement.innerText = "DONE";
      if(onResultAvailable !== null){
        // console.log("Avaible")
        onResultAvailable(response.data.receivedData[2]);
      }
      gsap.to(resultContainerRef.current, { opacity: 1, duration: 2 });
      gsap.to(resultContainerRef.current, { zIndex: 15 });

      setSessionId(response.data.receivedData[3]);
      // console.log("Sessions", response.data.receivedData[3]);
    } catch (error) {
      // console.error("Errore nell'invio dei dati:", error);
    }
  };

  const provideSessionFeedback = async (feed) => {
    try {
      const requestBody = {
        sessionId: sessionId,
        collection: "Session_ARPD_Meter",
        feedback: feed,
      };

      const response = await axios.post(
        "https://addfeedbackonresult-xcule5rnvq-uc.a.run.app",
        requestBody
      );
    } catch (error) {
      console.error("Errore nell'invio dei dati:", error);
    }
  };

  async function provideComprehensiveFeedback(results, canvasElement, glassesFound) {

    let stateGeneral = true;
    let newState = { ...controlStates };

    const data = results.facialTransformationMatrixes[0].data;
    const yawDeg =
      Math.atan2(-data[8], Math.sqrt(data[0] * data[0] + data[4] * data[4])) *
      (180 / Math.PI);
    const pitchDeg = Math.atan2(data[4], data[0]) * (180 / Math.PI);
    const rollDeg = Math.atan2(data[9], data[10]) * (180 / Math.PI);

    const feedbackDistance = calculateIrisDistanceCamera(
      results.faceLandmarks[0],
      isMobileView,
      width_mobile,
      width_desktop
    );

    const faceCenter = calculateFaceCenter(results.faceLandmarks[0]);
    const imageCenter = {
      x: canvasElement.width / 2,
      y: canvasElement.height / 2,
    };
    const isCentered = checkFaceCentering(faceCenter, imageCenter);

    const ctx = canvasElement.getContext("2d");
    const averageBrightness = calculateAverageBrightness(ctx, 0, 0, canvasElement.width, canvasElement.height);
    const brightnessThreshold = 80;
    const isBrightEnough = averageBrightness > brightnessThreshold;

    const checks = [
      {
        check: feedbackDistance.isCloseEnough,
        control: 'distance',
        message: feedbackDistance.message,
      },
      {
        check: isBrightEnough,
        control: 'brightness',
        message: "Insufficient lighting",
      },
      {
        check: !glassesFound,
        control: 'glasses',
        message: "Remove your glasses",
      },
      {
        check: isCentered,
        control: 'centering',
        message: "Place your face in the center",
      },
      {
        check: !(Math.abs(yawDeg) > 9 || Math.abs(pitchDeg) > 9 || (rollDeg < 8.5 && rollDeg >= 8.5)),
        control: 'alignment',
        message: "Correct facial alignment",
      },
    ];

    let foundFirstNotPassed = false;
    for (let i = 0; i < checks.length; i++) {
      const { check, control, message } = checks[i];

      if (!check && !foundFirstNotPassed) {
        newState[control] = 'inCorso';
        setFeedbackMessage(message);
        foundFirstNotPassed = true;
      } else if (!foundFirstNotPassed) {
        newState[control] = 'superato';
      } else {
        newState[control] = 'nonIniziato';
      }
    }

    const allPassed = Object.values(newState).every(status => status === 'superato');

    setControlStates(newState);

    return allPassed;
  }

  useEffect(() => {
    Object.entries(controlStates).forEach(([control, status]) => {
      animateControl(control, status, controlStates);
    });
  }, [controlStates]);

  useEffect(() => {
    function processFrame(approvedFrames) {
      return new Promise(resolve => {

        let count = 0;
        approvedFrames.current.forEach((image, index) => {
          for (let i = 0; i < 6; i++) {
            let results = faceMesh.detect(image);
            count += 1;
            frameCountRef.current += 1;

            const filteredLandmarks = filterLandmarks(results.faceLandmarks[0]);

            setCollectedData((currentData) => [ ...currentData, filteredLandmarks ]);
          }
          if (index === approvedFrames.current.length - 1) resolve();
        });
      });
    }

    if (isApproved.current) {
      processFrame(approvedFrames);
    }

  }, [isApproved.current]);

  const animateControl = (control, status, newState) => {
    const circleRef = circleRefs[control];
    const lineRef = lineRefs[control];

    const innerCircle = circleRef.current.querySelector(`.${styles.innerCircle}`);

    gsap.to(circleRef.current, { backgroundColor: "#00000000", duration: 0.5 });
    if (innerCircle) {
      gsap.to(innerCircle, {
        opacity: 0,
        duration: 0.5
      });
    }

    if (lineRef) {
      gsap.to(lineRef.current, { backgroundColor: "#D9D9D9", duration: 0.5 });
    }

    if (status === 'superato') {
      gsap.to(circleRef.current, { backgroundColor: feedbackDotsColor, duration: 0.5 });
    } else if (status === 'inCorso') {
      if (innerCircle) {
        gsap.to(innerCircle, {
          backgroundColor: feedbackDotsColor,
          opacity: 1,
          duration: 0.5,
          width: '50%',
          height: '50%'
        });
      }
    }

    const currentIndex = controlOrder.indexOf(control);
    const previousControl = controlOrder[currentIndex - 1];
    const previousControlPassed = previousControl ? newState[previousControl] === 'superato' : true;

    if (lineRef && status === 'superato' && previousControlPassed) {
      gsap.to(lineRef.current, { backgroundColor: {primaryColor}, duration: 0.5 });
    } else if (lineRef && status !== 'nonIniziato' && !previousControlPassed) {
      gsap.to(lineRef.current, { backgroundColor: "#D9D9D9", duration: 0.5 });
    }
  };

  async function analyzeVideo() {
    if (!isReady || !privacyPolicy) return;

    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const ctx = canvasElement.getContext("2d", { willReadFrequently: true });
    stagingImage.current = null;

    async function loop() {
      if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;

        ctx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = ctx.getImageData(0, 0, canvasElement.width, canvasElement.height);
        ctx.save();
        ctx.translate(canvasElement.width, 0);
        ctx.scale(-1, 1);

        ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        ctx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

        if (faceMesh && instructionDoneRef.current) {
          let results = await faceMesh.detect(imageData);
          const classifyResults = imageClassifier.classify(imageData);

          if (results && results.faceLandmarks.length > 0 && classifyResults) {
            const categories = classifyResults.classifications[0].categories;
            const glassesFound = categories.some(
              (category) => (category.categoryName === "sunglasses" || category.categoryName === "sunglass") && category.score > 0.0
            );

            if (glassesFound) {
              setHasGlassesOn(true);
            }

            const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
            const newCanvasHeight = canvasElement.offsetWidth / videoAspectRatio;
            const feedbackElement = document.getElementById("feedbackGeneral");

            calculateIrisDistanceCamera(results.faceLandmarks[0]);
            let controls = true;
            if (!measurementStartedRef.current) {
              controls = await provideComprehensiveFeedback(results, canvasElement, glassesFound);
            }

            if (controls) {

              if (frameCountRef.current < stay) {
                approvedFrames.current.push(imageData);
                frameCountRef.current += 1;

                if (feedbackElement !== null) {
                  feedbackElement.innerText = "Ok! Look at the camera";
                  feedbackElement.style.color = feedbackDotsColor;
                }
              } else if (frameCountRef.current == stay) {
                measurementStartedRef.current = true;

                isApproved.current = true;
                if (feedbackElement !== null) {
                  feedbackElement.innerText = "Measurement in progress";
                  feedbackElement.style.color = feedbackDotsColor;
                }
              }

            } else if (frameCountRef.current === maxFrame) {
              if (feedbackElement !== null && !isResult) {
                feedbackElement.innerText = "Measurement taken. We are calculating your PD";
                feedbackElement.style.color = feedbackDotsColor;
                frameCountRef.current += 1;
              }
            }
          }
        }
      }

      requestAnimationFrame(loop);
    }

    loop();
  }

  function isIpadDevice() {
    return /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
  }

  async function setupCamera(videoRef, isMobileView) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            // console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
          });
        })
        .catch((err) => {
          // console.error(`${err.name}: ${err.message}`);
        });

      try {
        let stream = null;

        if (isIpadDevice()) {
          if (orientation === 'landscape') {
            stream = await navigator.mediaDevices.getUserMedia({
              video: {
                width: { ideal: 640, min: 640, max: 640 },
                height: { ideal: 480, min: 480, max: 480 },
                facingMode: "user"
              },
              audio: 0,

            });
            videoRef.current.srcObject = stream;

          } else {
            stream = await navigator.mediaDevices.getUserMedia({
              video: {
                width: { ideal: 480, min: 480, max: 480 },
                height: { ideal: 640, min: 640, max: 640 },
                facingMode: "user"
              },
              audio: 0,
            });
            videoRef.current.srcObject = stream;
          }
        } else {
          if (isMobileView) {
            stream = await navigator.mediaDevices.getUserMedia({
              video: {
                width: { ideal: 480, min: 480, max: 480 },
                height: { ideal: 480, min: 480, max: 480 },
                facingMode: "user"
              },
              audio: 0,

            });
            videoRef.current.srcObject = stream;

          } else {
            stream = await navigator.mediaDevices.getUserMedia({
              video: {
                width: { ideal: 640, min: 640, max: 640 },
                height: { ideal: 480, min: 480, max: 480 }
              }
            });
            videoRef.current.srcObject = stream;
          }
        }

      } catch (error) {
        console.error('Errore nell\'accesso alla camera:', error);
        if (error.name === "NotAllowedError") {
          alert("L'accesso alla camera è stato negato. Per favore, concedi l'accesso alla camera.");
        } else if (error.name === "NotFoundError") {
          alert("Nessuna camera trovata.");
        } else if (error.name === "NotReadableError") {
          alert("La camera è già in uso da un'altra applicazione.");
        } else {
          alert("Errore sconosciuto nell'accesso alla camera.");
        }
      }
    }
  }

  return (
    <>
      <div className={containerClassName}>
        <ARPDMeter_CheckLicence isAllowed={isAllowed} isLicenseValid={isLicenseValid} animateProps={animateProps} isMobileView={isMobileView} logoArPDMeter={logoArPDMeter}
          handleUserStart={handleUserStart}
        />

        <div ref={permissionPolicyRef} className={styles.permissionPolicy} >
          <PermissionAndPolicy accepAction={handleAcceptPrivacyPolicy} consumptionRef={arpd_meter_consumptionRef}></PermissionAndPolicy>
        </div>

        <>
          <video ref={videoRef} style={{ display: 'none' }}></video>

          <ARPDMeter_Feedback
            feedbackContainerClassName={feedbackContainerClassName}
            feedbackContainerRef={feedbackContainerRef}
            circleContainerClassName={circleContainerClassName}
            circleRefs={circleRefs}
            lineRefs={lineRefs}
            controlStates={controlStates}
            feedbackMessage={feedbackMessage}
            circleClassName={circleClassName}
            feedbackDotsColor={feedbackDotsColor}
          />

          <div className={styles.containerPd} ref={pdContainerRef}>

            <canvas
              ref={canvasRef}
              style={{
                backgroundColor: "white",
                borderRadius: '30px',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%)'
              }}
            ></canvas>

            <GlassesContainer containerRef={arpdmeterIstructionRef} onClicked={handleGetStarted} >
              <div className={styles.istructionContainer}>
                <p className={styles.istructionTitle}> Recommendations </p>
                <p> - position yourself in a place with good lighting </p>
                <p> - place your face in the center of the frame </p>
                <p> - align your face correctly with the camera </p>
                <p className={styles.getStartedButtonTwo}> Get Started </p>

              </div>
            </GlassesContainer>

            <ResultsDisplay
              isResult={isResult}
              serverResponse={serverResponse}
              onRicalculate={setRicalcolate}
              feedbackContainerRicalcolateClassName={feedbackContainerRicalcolateClassName}
              feedbackResultsClassName={feedbackResultsClassName}
              feedbackResultLargeClassName={feedbackResultLargeClassName}
              feedbackResultsSmallClassName={feedbackResultsSmallClassName}
              feedbackResultSmallClassName={feedbackResultSmallClassName}
              resultContainerRef={resultContainerRef}
              handleDownVote={handleDownVote}
              handleUpVote={handleUpVote}
              feedback={feedback}
              fontColorResult={fontColorResult}
            ></ResultsDisplay>



          </div>
        </>
        {privacyPolicy && (
  <ARPDMeter_BottomContainer
    isResult={isResult}
    handleUpVote={handleUpVote}
    handleDownVote={handleDownVote}
    feedback={feedback}
    primaryColor={primaryColor}
    showTooltip={showTooltip}
    alertTooltip={alertTooltip}
  />
)}


  

        <ARPDMeter_Footer></ARPDMeter_Footer>
      </div>
    </>
  );
}

export default ARShades_PD_Interface;
