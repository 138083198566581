import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import styles from "../style/ARShades_PD_Interface.module.css";
import GlassesContainer from './GlassesContainer';
import thumbsUp from '../asset/icon/thumbs-down.svg';
import thumbsDown from '../asset/icon/thumbs-up.svg';

const ResultsDisplay = ({
  isResult,
  serverResponse,
  onRicalculate,
  feedbackContainerRicalcolateClassName,
  feedbackResultsClassName,
  feedbackResultLargeClassName,
  feedbackResultsSmallClassName,
  feedbackResultSmallClassName,
  resultContainerRef, // Assumendo che questo ref sia necessario per il componente
  handleDownVote,
  handleUpVote,
  feedback,
  fontColorResult
}) => (
  <GlassesContainer containerRef={resultContainerRef}>

    {isResult && (
      <div className={feedbackContainerRicalcolateClassName}>
        <div
          className={styles.buttonRicalculate}
          onClick={onRicalculate}
        >
          RECALCULATE
        </div>
      </div>
    )}

    {isResult && (
      <div className={feedbackResultsClassName}>
        {serverResponse.map((result, index) =>
          index === 2 ? (
            <div key={index}>
              <p className={feedbackResultLargeClassName} style={{color:fontColorResult}}>
                {result}
              </p>
              <p className={styles.subtitleResults} style={{color:fontColorResult}}> Pupillary Distance </p>
            </div>
          ) : null
        )}
        <div className={feedbackResultsSmallClassName}>
          {serverResponse.map((result, index) =>
            [0, 1].includes(index) ? (
              <div key={index} className={styles.subPdContainer}>
                <p className={feedbackResultSmallClassName} style={{color:fontColorResult}}>
                  {result}
                </p>
                <p className={styles.subtitleResults} style={{color:fontColorResult}}> {index === 0 ? 'right pd' : 'left pd'} </p>
              </div>
            ) : null
          )}
        </div>

       
      </div>
    )}
  </GlassesContainer>
);

export default ResultsDisplay;
