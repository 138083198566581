


function calculateFaceCenter(landmarks) {
    // Assumendo che landmarks[33] e landmarks[263] siano i punti centrali degli occhi
    const leftEye = landmarks[33];
    const rightEye = landmarks[263];
    const faceCenter = {
      x: (leftEye.x + rightEye.x) / 2,
      y: (leftEye.y + rightEye.y) / 2,
    };
    return faceCenter;
  }
  
  function checkFaceCentering(faceCenter, imageSize, tolerance = 0.20) {
    // Calcola il centro dell'immagine come valori normalizzati
    const normalizedImageCenter = {
      x: 0.5, // Centro normalizzato sull'asse X
      y: 0.5, // Centro normalizzato sull'asse Y
    };
  
    // Utilizza i valori normalizzati per calcolare la distanza
    const distance = Math.sqrt(Math.pow(faceCenter.x - normalizedImageCenter.x, 2) + Math.pow(faceCenter.y - normalizedImageCenter.y, 2));
    // Verifica se la distanza è entro una soglia di tolleranza (anche questa normalizzata)
    return distance <= tolerance;
  }
  

  function calculatePupillaryDistance(landmarks) {
    // Assumendo che landmarks[33] e landmarks[263] siano i punti centrali degli occhi
    const leftEye = landmarks[33];
    const rightEye = landmarks[263];
    const pd = Math.sqrt(Math.pow(rightEye.x - leftEye.x, 2) + Math.pow(rightEye.y - leftEye.y, 2));
    return pd;
  }
  
  function isFaceCloseEnough(pd, minPdThreshold = 0.20) {
    // Verifica se la distanza pupillare in pixel supera una soglia minima
    // console.log(pd,minPdThreshold)
    return pd >= minPdThreshold;
  }


  function midpoint3D(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
      z: (p1.z + p2.z) / 2,
    };
  }

  function removeOutliers(data,label) {
    var sum = 0; // stores sum of elements
    var sumsq = 0; // stores sum of squares
    var l = data.length;
    if (l < 3) {
      return data;
    }
    for (var i = 0; i < data.length; ++i) {
      sum += data[i];
      sumsq += data[i] * data[i];
    }
    var mean = sum / l;
    var varience = sumsq / l - mean * mean;
    var sd = Math.sqrt(varience);
    var data3 = new Array(); // uses for data which is 3 standard deviations from the mean
    for (var i = 0; i < data.length; ++i) {
      if (data[i] > mean - 3 * sd && data[i] < mean + 3 * sd) {
        data3.push(data[i]);
      } else {
        // console.log("removed value:", data[i], label);
      }
    }
    console.log(data)
    return data3;
  }

  function distance3D(p1, p2, screenRatio) {
    return Math.hypot(
      p2.x - p1.x,
      p2.y / screenRatio - p1.y / screenRatio,
      p2.z - p1.z
    );
  }

  function distance3DTwo(point1, point2) {
    return Math.hypot(point2.x - point1.x, point2.y - point1.y, point2.z - point1.z);
}

  function distance2D(p1, p2) {
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
  }

  function calculateKidScore(
    facewidthrate,
    faceheightrate,
    eyePosition,
    noseDistance
  ) {
    let score = 0;

    // Weights can be adjusted based on their significance in determining age
    const weights = {
      facewidthrate: 1,
      faceheightrate: 1,
      eyePosition: 1,
      noseDistance: 0.5,
      faceRound: 0.2,
    };

    // Midpoints based on your provided values
    const midpoints = {
      facewidthrate: 0.05,
      faceheightrate: 0.031,
      eyePosition: 0.53,
      noseDistance: 0.2,
      faceRound: 1.59,
    };

    const midpointIncrement = 0.2;

    //check face roundness
    const faceRound = facewidthrate / faceheightrate;
   
    // Calculate score for face width rate
    if (facewidthrate > midpoints.facewidthrate) {
      score +=
        (facewidthrate - midpoints.facewidthrate) * weights.facewidthrate +
        midpointIncrement;
    }

    // Calculate score for face height rate
    if (faceheightrate > midpoints.faceheightrate) {
      score +=
        (faceheightrate - midpoints.faceheightrate) * weights.faceheightrate +
        midpointIncrement;
    }

    // Calculate score for eye position
    if (eyePosition > midpoints.eyePosition) {
      score +=
        (eyePosition - midpoints.eyePosition) * weights.eyePosition +
        midpointIncrement;
    }

    // Calculate score for nose distance
    if (noseDistance < midpoints.noseDistance) {
      score +=
        (midpoints.noseDistance - noseDistance) * weights.noseDistance +
        midpointIncrement;
    }

    // Calculate score for face roundness
    if (faceRound < midpoints.faceRound) {
      score +=
        (midpoints.faceRound - faceRound) * weights.faceRound +
        midpointIncrement;
    }

    return score;
  }

  function adjustCanvasSizeToScreen(videoElement, canvasElement) {
    const screenAspectRatio = window.innerWidth / window.innerHeight;
    const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
  
    let newWidth, newHeight;
  
    if (videoAspectRatio > screenAspectRatio) {
      // Se il video è più "largo" dello schermo, adatta alla larghezza dello schermo
      newWidth = window.innerWidth;
      newHeight = window.innerWidth / videoAspectRatio;
    } else {
      // Se il video è più "alto" dello schermo, adatta all'altezza dello schermo
      newWidth = window.innerHeight * videoAspectRatio;
      newHeight = window.innerHeight;
    }
  
    // Applica le nuove dimensioni al canvas
    canvasElement.style.width = `${newWidth}px`;
    canvasElement.style.height = `${newHeight}px`;
  
    // Assicurati di aggiornare anche le dimensioni interne del canvas per mantenere la qualità dell'immagine
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
  }

  async function setupCamera(videoRef, isMobileView) {

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
        });
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      });

      try {
        let stream = null ;
        // if(isMobileView){
        //  stream = await navigator.mediaDevices.getUserMedia({
        //   video: {
        //     width: { ideal: 480 , min:480 , max: 480},
        //     height: { ideal: 480, min:480 , max: 480 },
        //     facingMode: "user"
        //   },
        //   audio: 0,
          
        // });
        // }else {
          stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { ideal: 640 , min:640 , max: 640},
              height: { ideal: 480, min:480 , max: 480}
            }
          });
        // }
        videoRef.current.srcObject = stream;

      } catch (error) {
        console.error('Errore nell\'accesso alla camera:', error);
    if (error.name === "NotAllowedError") {
      alert("L'accesso alla camera è stato negato. Per favore, concedi l'accesso alla camera.");
    } else if (error.name === "NotFoundError") {
      alert("Nessuna camera trovata.");
    } else if (error.name === "NotReadableError") {
      alert("La camera è già in uso da un'altra applicazione.");
    } else {
      alert("Errore sconosciuto nell'accesso alla camera.");
    }
      }
    }


    // videoRef.current.onloadedmetadata = () => {
    //   const expectedWidth = isMobileView ? 480 : 640; // Dimensioni attese basate sulla vista
    //   if (videoRef.current.videoWidth !== expectedWidth) {
    //     console.log("Le dimensioni del video non corrispondono alle dimensioni attese. Ciò potrebbe indicare che la camera è già in uso da un'altra applicazione con differenti requisiti di dimensione. Per favore, chiudi le altre applicazioni che usano la camera e riprova.");
    //     // Qui puoi implementare ulteriori azioni, come spegnere la camera.
    //   }else{
    //     console.log("OK")
    //   }
    // };

    
  }

  function calculateAverageBrightness(ctx, x, y, width, height) {
    // Calcola l'altezza di una "riga" se l'immagine è divisa in 10 parti uguali
    const rowHeight = height / 10;

    // Calcola gli indici di inizio e fine per le righe 3, 4 e 5
    const startRow = Math.floor(rowHeight * 2); // Inizio della terza riga
    const endRow = Math.ceil(rowHeight * 5); // Fine della quinta riga

    const imageData = ctx.getImageData(x, y + startRow, width, endRow - startRow);
    const data = imageData.data;
    let totalBrightness = 0;
    let pixelCount = 0;

    for (let i = 0; i < data.length; i += 4) {
        // Calcola la luminosità per il pixel corrente
        const brightness = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
        totalBrightness += brightness;
        pixelCount++;
    }

    return totalBrightness / pixelCount; // Restituisce la luminosità media delle righe 3, 4 e 5
}

function calculateIrisDistanceCamera(landmarks, isMobileView, width_mobile,width_desktop) {
  // Assunzioni
  const realIrisDiameterMM = 11.7; // Dimensione reale dell'iride in mm

  // Stima approssimativa della lunghezza focale della camera in pixel
  const focalLengthPixels = isMobileView ? width_mobile : width_desktop; 

  // Calcolo della dimensione dell'iride nell'immagine in pixel
  const x_471 = landmarks[471].x * focalLengthPixels; // Adatta alla lunghezza focale
  const y_471 = landmarks[471].y * (isMobileView ? width_desktop : width_mobile); // Adatta per orientamento verticale in mobile
  const x_469 = landmarks[469].x * focalLengthPixels; // Adatta alla lunghezza focale
  const y_469 = landmarks[469].y * (isMobileView ? width_desktop : width_mobile); // Adatta per orientamento verticale in mobile

  const irisDiameterPixels = Math.sqrt(Math.pow(x_469 - x_471, 2) + Math.pow(y_469 - y_471, 2));

  // Utilizzo della lunghezza focale approssimativa per calcolare la distanza dell'iride dalla camera in pixel
  const distanceMM = (realIrisDiameterMM * focalLengthPixels) / irisDiameterPixels;

  let feedback = {
      isCloseEnough: false, // Stato iniziale
      message: "" // Messaggio iniziale vuoto
  };

  if (isMobileView) {
      if (distanceMM/10 < 40 && distanceMM/10 > 30) {
          feedback.isCloseEnough = true;
          // feedback.message = "Distanza corretta";
      } else if (distanceMM/10 <= 30) {
           feedback.message = "Move away from the camera";
         // feedback.message = distanceMM/10

      } else {
          feedback.message = "Move closer to the camera";
          // feedback.message = distanceMM/10

      }
  } else {
      if (distanceMM/10 < 50 && distanceMM/10 > 40) {
          feedback.isCloseEnough = true;
          // feedback.message = "Distanza corretta";
      } else if (distanceMM/10 <= 40) {
          feedback.message = "Move away from the camera";
          // feedback.message = distanceMM/10
      } else {
          feedback.message = "Move closer to the camera";
          // feedback.message = distanceMM/10

      }
  }

  return feedback;
}



  export { calculateFaceCenter, checkFaceCentering, calculatePupillaryDistance , isFaceCloseEnough, adjustCanvasSizeToScreen , calculateKidScore, distance2D, setupCamera, calculateAverageBrightness, calculateIrisDistanceCamera/*, altre funzioni... */ };
