import React, { useRef } from "react";
import styles from "../style/BottomContainer.module.css";
import PropTypes from 'prop-types';

function ARPDMeter_BottomContainer({ isResult, handleUpVote, handleDownVote, feedback, primaryColor, showTooltip }) {
  const alertTooltipRef = useRef(null);

  return (
    <div className={styles.bottomContainer}>
      {/* Contenitore per il feedback, visibile solo quando isResult è true */}
      <div className={styles.iconContainer}>

      {isResult && (
        <>
          <div
            className={`${styles.feedbackIcons} ${feedback === true ? styles.activeIcon : ''}`}
            onClick={handleUpVote}
          >
            {/* SVG per il pulsante di upvote */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={feedback === true ? primaryColor : 'none'}
              stroke={primaryColor}
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
            </svg>
          </div>
          <div
            className={`${styles.feedbackIcons} ${feedback === false ? styles.activeIcon : ''}`}
            onClick={handleDownVote}
          >
            {/* SVG per il pulsante di downvote */}
            <svg
              width="24"
              height="24"
              strokeWidth="1"
              viewBox="0 0 24 24"
              fill={feedback === false ? primaryColor : 'none'}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.4724 3.5H4.1C3.76863 3.5 3.5 3.76863 3.5 4.1V13.9C3.5 14.2314 3.76863 14.5 4.1 14.5H6.86762C7.57015 14.5 8.22116 14.8686 8.5826 15.471L11.293 19.9884C11.8779 20.9631 13.2554 21.0558 13.9655 20.1681C14.3002 19.7497 14.4081 19.1937 14.2541 18.6804L13.2317 15.2724C13.1162 14.8874 13.4045 14.5 13.8064 14.5H18.3815C19.7002 14.5 20.658 13.246 20.311 11.9738L18.4019 4.97376C18.1646 4.10364 17.3743 3.5 16.4724 3.5Z"
                stroke={primaryColor}
                strokeLinecap="round"
              />
              <path
                d="M7 14.5L7 3.5"
                stroke={primaryColor}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          Give us Feedback
          </>
      )}
              </div>

      {/* Contenitore per Private Session, sempre visibile e allineato a destra */}
      <div ref={alertTooltipRef} className={styles.alert}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={primaryColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="12" />
          <line x1="12" y1="16" x2="12.01" y2="16" />
        </svg>
        Private Session
        <div className={`${styles.tooltip} ${showTooltip ? styles.show : ''}`}>
          Video is not recorded or sent to our servers.
        </div>
      </div>
    </div>
  );
}

ARPDMeter_BottomContainer.propTypes = {
  isResult: PropTypes.bool.isRequired,
  handleUpVote: PropTypes.func.isRequired,
  handleDownVote: PropTypes.func.isRequired,
  feedback: PropTypes.bool,
  primaryColor: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool.isRequired,
};

export default ARPDMeter_BottomContainer;
