import styles from "../style/ARShades_PD_Interface.module.css"
import React from 'react';


function Accordion({ panel, openPanel, handlePanelChange, children, title }) {
    const isOpen = openPanel === panel;
  
    return (
      <div className={styles.accordionClose} onClick={() => handlePanelChange(panel)}>
        <div className={styles.accordionFlex}>
        <div className={styles.leftAccordionPolicy}>
        <p className={styles.titlePolicyAccordion} onClick={() => handlePanelChange(panel)}>{title}</p>
        </div>
        <div className={styles.rightAccordionPolicy}>

        <p className={styles.readPolicy} onClick={()=> handlePanelChange(panel)}> Read </p>
        </div>
        </div>
        {isOpen && children}
      </div>
    );
  }
  
  export default Accordion;