import React from 'react';
import styles from "../style/ARShades_PD_Interface.module.css";

const GlassesContainer = ({ children, containerRef, onClicked }) => {
  return (
    <div className={styles.resultsContainer} ref={containerRef} onClick={onClicked ? onClicked : undefined}>
      <div className={styles.overlayResults}>
        {children}
      </div>
    </div>
  );
};

export default GlassesContainer;
