import { useState, useEffect } from "react";
 import styles from "../style/ARShades_PD_Interface.module.css"
 import axios from 'axios';
 import React from 'react';

import Accordion from "./Accordion";
import Camera from './ARPDMeter_Camera'
import { policyConverter } from "../model/Policy";
import { termsConverter} from "../model/Terms";

function PermissionAndPolicy(props) {

const [openPanel, setOpenPanel] = useState(0);
const [error, setError] = useState(false);
const [isCameraAccepted, setIsCameraAccepted] = useState(false);
const [displayCameraAccept, setIsCameraAcceptedDisplay] = useState(false);
const [privacyPolicy, setPrivacyPolicy] = useState();
const [termsAndCondition, setTermAndCondition] = useState()
const [readPolicy , setRead] = useState(false)

useEffect(()=>{

const fetchPolicies = async () => {
  try {
    const response = await axios.post(
      'https://getprivacypolicyandterms-xcule5rnvq-uc.a.run.app',
      // 'http://127.0.0.1:5001/arshades-7e18a/us-central1/getPrivacyPolicyAndTerms',
      {}, // Dato che la tua richiesta fetch originale non invia body, qui si passa un oggetto vuoto
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response)
      if (!response.data) {
        throw new Error('Network response was not ok');
      }
      const data = await response.data;
      // console.log("Data", data);
      // Converti i dati ricevuti con i tuoi convertitori
      const convertedPrivacyPolicy = policyConverter(data.privacyPolicy);
      const convertedTermsAndCondition = termsConverter(data.termsAndCondition);
      setPrivacyPolicy(convertedPrivacyPolicy);
      setTermAndCondition(convertedTermsAndCondition);
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
 };

 if(props.consumptionRef !== null){
 fetchPolicies();
  }

},[props.consumptionRef])

const handlePanelChange = (panel) => {
    if(panel == openPanel){
        setOpenPanel(0);
    }else{
        setOpenPanel(panel);
    }

  }

  function handleCameraAccept() {
    setIsCameraAccepted(true);
  }

  function handlePrivacyAccept() {
    if (error) {
      console.log("Si è verificato un errore durante l'accesso alla fotocamera:", error);
      return;
    }
    if (!isCameraAccepted) {
      setIsCameraAcceptedDisplay(true)
      console.log("Devi prima accettare l'utilizzo della fotocamera");
      return;
    }
    // console.log("click");
    props.accepAction();
  }
  

  function handleCameraError(error) {
    setError(error);
  }
  
    return (
      <>
            
            <img className={styles.logoArpdMeterPolicy} src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/ARPDMeter%2Ficon%2FARShades%20PD%20Meter%20icon%202.png?alt=media&token=fa39133f-8d16-4e3d-bbf0-1a9aea599182"></img>
           
            
            <div className={styles.policyTitle}>
                Please read and accept the Legal Terms to proceede
            </div>
            <Accordion
        panel={1}
        openPanel={openPanel}
        handlePanelChange={handlePanelChange}
        title={"Terms and Condition"}
      >
        
        <div className={styles.policyOpen}>
        {termsAndCondition?.description_en.map((paragraph, index) => {
          return(
          <div key={index}>
          <h3 className={styles.titlePolicy}>{paragraph.titolo}</h3>
          <p className={styles.paragrafoPolicy}>{paragraph.paragrafo}</p>
          </div>
        )})}
        </div>
      </Accordion> 

       <Accordion
        panel={2}
        openPanel={openPanel}
        handlePanelChange={handlePanelChange}
        title={"Privacy Policy"}
      >
      
      <div className={styles.policyOpen}>
        {privacyPolicy?.description_en.map((paragraph, index) => {
          return(
          <div key={index}>
          <h3 className={styles.titlePolicy}>{paragraph.titolo}</h3>
          <p  className={styles.ParagrafoPolicy}>{paragraph.paragrafo}</p>
          </div>
        )})}
        </div>
      </Accordion>

            <div className={styles.acceptContainer} onClick={handlePrivacyAccept}>Accept</div>
            {!isCameraAccepted && (
        <Camera acceptAction={handleCameraAccept} onError={handleCameraError} message={error} display={displayCameraAccept} />
      )}
            {error && <div><p> UNABLE TO DETECT CAMERA</p><p className={styles["error-display-camera"]}>Allow access to your webcam and check that it is not already in use.</p></div>}

        </>

    );


}

export default PermissionAndPolicy;