import React, { useEffect, useState } from "react";

function Camera(props) {
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    async function checkCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setHasConsent(true);
        props.acceptAction();
      } catch (error) {
        props.onError(error);
      }
    }
    checkCameraPermission();
  }, []);

  if (!navigator.mediaDevices) {
    return <p>La fotocamera non è supportata dal tuo browser</p>;
  }

  if (!hasConsent) {
    if(props.message === false){
        if(props.display){
        return <p >Per proseguire devi prima concedere a WebVto.it il permesso di utilizzare la fotocamera</p>;
        }
    }
    // props.onError(true);
  }

  return (
    <div>
      
    </div>
  );
}

export default Camera;